

function UploadFiles( whistle, $, params ){

	var me = this;

	me.clickUploadLink = function( ev ){
		var $file = $(this).parent().parent().find('input[type=file]');
		$file.click();
		ev.preventDefault();
		$(this).blur();
	};

	me.changeOfUploadFiles = function(){
		var files = $(this).prop('files');
		var $file = $(this);
		var fileNamesSelector = $(this).data('file-names-class') ? '.' + $(this).data('file-names-class') : '.fileNames';
		if( !files ){
			files = [ { 'name' : this.value } ];
		}
		var names = $.map(files, function(val) { return val.name; });

		if( names && names.length ){
			
			$(fileNamesSelector).empty().removeClass('hide');
			$( names ).each( function( ){

				$(fileNamesSelector).append( '<div class="fileToUpload">' +
					"<i class='fa fa-paperclip'></i>" +
					this + '</div>' );
			} );
			$(fileNamesSelector).append('<button type="button" class="btn btn-default btn-xs fa fa-times removeFilesButton"></button>');
			$(fileNamesSelector).find('.removeFilesButton').click(function (){
				me.clickRemoveFilesButton($file, fileNamesSelector);
			});
		}
	};

	me.clickRemoveFilesButton = function(control, fileNamesSelector){
		// debugger
	    control.replaceWith( control = control.clone( true ) );
	    control.val(''); 
	    $(fileNamesSelector).empty();
	    me.observe();
	}

	me.observe = function(){
		$('.uploadLink').off().click( me.clickUploadLink );
		$('.uploadFiles').off().change( me.changeOfUploadFiles );
	};

	me.observe();
}