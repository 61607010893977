

function Submission(){
	var me = this;
	me.REPORT = 1;
	me.QUESTION = 2;
	me.CLOSED = 50;

}

function Report(){
	var me = this;

	me.PRELIMINARY_REPORT = 1;
	me.QUESTION_SUMMARY = 2;
	me.NOTE = 3;
	me.NOTIFICATION = 4;
	me.INQUIRY_REPORT = 5;
	me.ANALYSIS  = 6;
}
